.acsl-theory {
    h2 {
        font-weight: 300;
        font-size: 14px;
    }

    .ta-card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(#e7e7e7, 1);
        border-radius: 5px;
        font-size: 14px;
        margin-bottom: 20px;

        .ta-card-header {
            padding: 0.5rem 1rem;
            margin-bottom: 0;
            background-color: #1b365d;
            color: #ffffff;
            border-bottom: 1px solid #e7e7e7;
            border-radius: 5px 5px 0 0;
        }

        .ta-card-body {
            flex: 1 1 auto;
            padding: 15px;
        }

        .ta-card-footer {
            padding: 10px 15px;
            background-color: #ffffff;
            border-top: 1px solid #e7e7e7;

            a{
                margin-right: 15px;
                text-decoration: none;
                color: rgba(#212529, 0.7);
                transition: all ease-in-out 0.3s;
                font-size: 13px;

                i{
                    margin-right: 5px;
                }

                &:hover{
                    color: rgba(#212529, 1);
                }
            }
        }
    }

    .button-primary {
        color: #fff;
        background-color: rgba(#0d6efd, 1);
        border: solid 1px rgba(#0d6efd, 1);
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        padding: 6px 12px;
        font-size: 16px;
        border-radius: 4px;
        transition: all ease-in-out 0.3s;

        &:hover {
            background-color: rgba(#0d6efd, 0.8);
            border: solid 1px rgba(#0d6efd, 0.8);
        }
    }

    .button-secondary {
        color: #fff;
        background-color: rgba(#6c757d, 1);
        border: solid 1px rgba(#6c757d, 1);
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        padding: 6px 12px;
        font-size: 16px;
        border-radius: 4px;
        transition: all ease-in-out 0.3s;

        &:hover {
            background-color: rgba(#6c757d, 0.8);
            border: solid 1px rgba(#6c757d, 0.8);
        }
    }

    .button-success {
        color: #ffffff;
        background-color: rgba(#198754, 1);
        border: solid 1px rgba(#198754, 1);
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        padding: 6px 12px;
        font-size: 16px;
        border-radius: 4px;
        transition: all ease-in-out 0.3s;

        &:hover {
            background-color: rgba(#198754, 0.8);
            border: solid 1px rgba(#198754, 0.8);
        }
    }

    .button-info {
        color: #ffffff;
        background-color: rgba(#0dcaf0, 1);
        border: solid 1px rgba(#0dcaf0, 1);
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        padding: 6px 12px;
        font-size: 16px;
        border-radius: 4px;
        transition: all ease-in-out 0.3s;

        &:hover {
            background-color: rgba(#0dcaf0, 0.8);
            border: solid 1px rgba(#0dcaf0, 0.8);
        }
    }
}
