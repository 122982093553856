@mixin mobile-min {
    @media (min-width: 767px) {
        @content;
    }
}

.side-navigation {
    @include mobile-min {
        .collapse:not(.show) {
            display: block;
        }
    }    

    .menu-title {
        padding: 8px 20px;
        font-weight: 400;
        text-transform: uppercase;
        background-color: #71acff;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        margin-bottom: 0;

        a {
            color: #ffffff;

            @include mobile-min {
                display: none;
            }
        }
    }

    .side-menu {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            border-bottom: solid 1px rgba(#e7e7e7, 0.8);
        }

        a ,button{
            color: #212529;
            text-decoration: none;
            display: block;
            padding: 7px 20px;
            font-size: 14px;

            &:hover {
                background-color: #ffffff;
            }

            &.active {
                background-color: #ffffff;
                color: #1b365d;
            }
        }
        button{
            border:none;
            outline:none;
            width: 100%;
            text-align: start;
        }
    }
}

.dropdown-menu{
    width: 100% !important;
 
}

