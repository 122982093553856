@mixin mobile{
    @media(max-width: 767px){
        @content
    }
}

.footer{
    color: #ffffff; 
    background-color: #1B365D;

    .policy-terms{
        text-align: right;
        font-size: 13px;

        a{
            color: #ffffff;
            margin-left: 10px;
            text-decoration: none;

            &:hover{
                text-decoration: underline;
            }
        }

        @include mobile{
            text-align: left;
            padding: 10px
        }
    }

    .footer-nav{
        margin-bottom:30px;
        display: flex;
        justify-content: space-between;

        .f-item{
            margin-right: 15px;
        }

        .f-item:last-child{
            margin-right: 0;
        }
    }

    .footer-bottom{
        border-top: solid 1px rgba(255,255,255, 0.2);
        padding-top: 20px;
    }

    ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
        font-size: 14px;

        li{
            margin: 5px 0;

            i{
                margin-right: 5px;
            }
        }

        a{
            color: #ffffff;
            text-decoration: none;

            &:hover{
                text-decoration: underline;
            }
        }

        .nav-title{
            text-transform: uppercase;
            font-weight: 500;
            margin-top: 10px;
        }

        .social-media{
            a{
                margin-right: 10px;
                font-size: 32px;
            }
        }
    }
}