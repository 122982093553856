@mixin tabview {
    @media (min-width: 768px) and (max-width: 1180px) {
        @content;
    }
}

@mixin mobile{
    @media (max-width:767px){
        @content;
    }
}
.top-header {
    background-color:#F7F7F7;
    border-bottom: solid 1px #e4e4e4 ;}
.top-menu {
    .company-logo{
        min-width: 20px;
    }

    .register-now {
        margin-top: 5px;
        color: #fff;
        background-color: rgba(#1b365d, 1);
        border: solid 1px rgba(#1b365d, 1);
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        padding: 5px 10px;
        font-size: 16px;
        border-radius: 5px;
        transition: all ease-in-out 0.3s;

        &:hover {
            background-color: rgba(#1b365d, 0.9);
            border: solid 1px rgba(#1b365d, 0.9);
        }
    }

    .navbar-light {
        .navbar-nav {
            .nav-link {
                color: #212529;

                &.active {
                    color: #1b365d;
                    font-weight: 400;
                }

                &:hover {
                    color: #1b365d;
                }
            }

            @include tabview {
                flex-direction: row;                

                .nav-item {
                    margin-right: 15px;
                }
            }

            @include mobile {
                margin-top: 7px;

                .nav-link{
                    border-top: solid 1px #e7e7e7;
                }
            }
        }
    }
}
