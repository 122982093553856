.theme-btn {
  background-color: #1b365d;
  color: white;
  padding: 8px 10px;
  font-size: 14px;
  margin: 2px;
  font-weight: 300;
  outline: none;
  box-shadow: none;
  border: none;
}
