@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

.schedule-wrapper {
    font-size: 14px;
    
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .sch-row {
        border: solid 1px #e7e7e7;
    }

    .sch-body {
        padding: 15px;
    }

    .sch-title {
        background-color: #1b365d;
        color: #ffffff;
        
        padding: 7px 15px;
    }

    .sch-description {
        margin-bottom: 15px;
    }

    .sch-actions {
        display: flex;
        justify-content: space-between;

        .action-item {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }

        @include mobile {
            flex-direction: column;

            .action-item {
                margin-bottom: 15px;
                background-color: #f7f7f7;
                padding: 10px;
            }
        }
    }

    // buttons
.button-primary-small {
    color: #fff;
    background-color: rgba(#0d6efd, 1);
    border: solid 1px rgba(#0d6efd, 1);
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 3px 10px;
    font-size: 14px;
    border-radius: 4px;
    transition: all ease-in-out 0.3s;

    &:hover {
        background-color: rgba(#0d6efd, 0.8);
        border: solid 1px rgba(#0d6efd, 0.8);
    }
}
}


