* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html::-webkit-scrollbar {
  display: none;
}

#topmenu {
  position: sticky !important;
  top: 0;
  z-index: 1;
  height: 77px !important;
}
.navbar-expand-lg {
  margin-top: -3px;
}
.navbar-brand {
  font-size: 1.25rem;
  margin-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand {
  margin-right: 0px !important;
  padding-top: 0 !important;
}

@media print {
  .print-btn {
    display: none;
  }
}
